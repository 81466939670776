import styled, { css } from 'styled-components';

import { colors } from '../../styles';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: calc(100vh - 40px);
`;

export const TopSectionContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  height: fit-content;
  background-color: ${colors.white};
  flex-wrap: wrap;
  padding: 20px;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;

  @media (max-width: 1050px) {
    justify-content: center;
    flex-direction: column-reverse;
    align-items: center;
    flex-wrap: nowrap;
  }
`;

export const MeImage = styled.img`
  display: flex;
  height: fit-content;
  height: auto;
  width: 100%;
`;

export const AccessibilityQuoteImage = styled.img`
  position: absolute;
  right: -20px;
  top: 60px;

  @media (max-width: 700px) {
    display: none;
  }
`;

export const ImageQuoteContainer = styled.div`
  position: relative;
  max-width: 750px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 700px) {
    margin-bottom: 0px;
  }
`;

export const QuickActionButton = styled.a<{ color?: string }>`
  background-color: ${({ color }) =>
    colors[(color || 'red') as keyof typeof colors]};
  border: none;
  color: ${({ color }) => (color ? colors.blue : colors.white)};
  border: 1px solid;
  border-color: ${colors.red};
  border-radius: 15px;
  font-size: 15px;
  padding: 15px;
  cursor: pointer;
  width: 125px;
  white-space: nowrap;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  text-decoration: none;
  > svg {
    margin-left: 10px;
  }

  ${({ color }) =>
    color &&
    css`
      margin-left: 20px;
      border-color: ${colors.blue};
      > svg {
        transform: rotate(45deg);
      }
    `}
`;

export const ActionBar = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  max-width: 450px;
  margin: 20px 0;

  @media (max-width: 700px) {
    justify-content: center;
    text-align: center;
  }
`;

export const ConnectWrapper = styled.div`
  color: ${colors.blue};
  margin-top: 40px;
  width: 100%;

  max-width: 450px;
  display: flex;
  align-items: center;

  @media (max-width: 700px) {
    justify-content: center;
    text-align: center;
    margin-top: 20px;
    padding-bottom: 20px;
  }
`;

export const ConnectButtonsWrapper = styled.div`
  display: flex;
  margin-left: 10px;
`;

export const ConnectButton = styled.a.attrs({
  target: '_blank',
})<{ brandColors?: { primary?: string; secondary?: string } }>`
  cursor: pointer;
  border: none;
  background-color: white;
  border-radius: 50%;
  width: 50px;
  margin: 0 10px;
  height: 50px;
  display: flex;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  align-items: center;

  :hover {
    background-color: ${({ brandColors }) =>
      brandColors?.primary || colors.red};
    color: ${({ brandColors }) => brandColors?.secondary || colors.white};
    > svg {
      > path {
        fill: ${({ brandColors }) => brandColors?.secondary || colors.white};
      }
    }
  }

  > svg {
    width: 22px !important;
    height: 22px !important;
    color: ${colors.blue};
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
