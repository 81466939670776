import styled from 'styled-components';

import { colors } from '../../styles';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 20px;
  align-items: center;
  justify-content: space-evenly;
  min-height: calc(100vh - 40px);
  flex-wrap: wrap;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 40%;
  justify-content: center;
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 2px solid ${colors.red};
  margin: 20px 0;
`;

export const Label = styled.h3`
  margin: 0px;
  color: ${colors.blue};
  font-weight: normal;
  font-size: 15px;
`;

export const Details = styled.p`
  margin: 0px;
  color: ${colors.blue};
  font-weight: bold;
  font-size: 18px;
`;

export const EmailWrapper = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${colors.blue};
`;

export const SvgButtonContainer = styled.div`
  position: relative;
`;

export const EmailMeButton = styled.a`
  text-decoration: none;
  position: absolute;
  top: 40%;
  left: 50%;
  cursor: pointer;
  background-color: ${colors.blue};
  padding: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
`;

export const LetsChat = styled.span`
  position: absolute;
  top: 45%;
  left: 20%;
  color: ${colors.blue};
`;
