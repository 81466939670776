import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

import FocusScope from '../FocusScope';

import {
  Wrapper,
  Logo,
  NavMenuButton,
  MobileSlideOut,
  NavMenuCloseButton,
  MobileLink,
  Orange,
} from './styled-componets';
import Link from './Link';

import { Routes } from '../../App';

const NavBar = () => {
  const [showNavMenu, setShowNavMenu] = useState(false);

  const [active, setActive] = React.useState(window.location.pathname);

  const Links = [...Object.values(Routes)];

  return (
    <Wrapper>
      <Logo aria-label={'Mind over Matt logo'} className="webLogo">
        Mind <Orange>/</Orange> Matt
      </Logo>
      <Logo aria-label={'Mind over Matt mobile logo'} className="mobileLogo">
        M<Orange>/</Orange>M
      </Logo>
      <div className="web">
        {Links.map((link) => (
          <Link
            key={link.url}
            active={active}
            link={link}
            onClick={setActive}
          />
        ))}
      </div>
      <div className="mobile" style={{ position: 'relative', height: '20px' }}>
        <NavMenuButton
          aria-label="open navigation menu"
          onClick={() => setShowNavMenu(true)}
          style={showNavMenu ? { display: 'none' } : {}}
        >
          <FontAwesomeIcon
            style={{ width: '20px', height: '20px' }}
            icon={faBars}
          />
        </NavMenuButton>

        {showNavMenu && (
          <FocusScope contain restoreFocus onEsc={() => setShowNavMenu(false)}>
            <MobileSlideOut>
              <NavMenuCloseButton
                aria-label="close navigation menu"
                onClick={() => setShowNavMenu(false)}
              >
                <FontAwesomeIcon
                  style={{ width: '20px', height: '20px' }}
                  icon={faTimes}
                />
              </NavMenuCloseButton>

              {Links.map((link) => (
                <MobileLink
                  key={link.url}
                  active={active}
                  link={link}
                  onClick={(active) => {
                    setShowNavMenu(false);
                    setActive(active);
                  }}
                />
              ))}
            </MobileSlideOut>
          </FocusScope>
        )}
      </div>
    </Wrapper>
  );
};

export default NavBar;
