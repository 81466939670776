const colors = {
  blue: '#252837',
  red: '#DD131A',
  orange: '#FC9803',
  white: '#F3F5FA',
} as const;

/*
Not Accessible combinations
  - blue and red
  - orange and red
  - orange and white
  - red and blue
  - red and orange
  - white and orange

Accessible combinations
  - blue and orange
  - blue and white
  - orange and blue
  - red and white
  - white and blue
  - white and red

*/

export default colors;
