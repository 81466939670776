import React from 'react';

import { Home, About, Contact } from './pages';

import NavBar from './components/NavBar';

import { useRoutes } from 'hookrouter';

// import { colors } from './styles';

// const DummyComponent = () => (
//   <div
//     style={{
//       flex: 1,
//       minHeight: 'calc(100vh - 50px)',
//       background: colors.orange,
//     }}
//   />
// );

const Routes = {
  HOME: {
    url: '/',
    ariaLabel: 'Home Page',
    component: Home,
  },
  // WOODWORKING: {
  //   url: '/wood-working',
  //   ariaLabel:
  //     'Wood working, find anything from my weekend projects to plans for sale.',
  // },
  ABOUT: {
    url: '/about',
    ariaLabel:
      'About, this is where you can learn more about me and my background.',
    component: About,
  },
  // PORTFOLIO: {
  //   url: '/portfolio',
  //   ariaLabel: 'Portfolio, this is where you can see my latest projects.',
  //   component: DummyComponent,
  // },
  // TESTIMONIALS: {
  //   url: '/testimonials',
  //   ariaLabel:
  //     'Testimonials, this is where you can hear what others think about me.',
  //   component: DummyComponent,
  // },
  CONTACT: {
    url: '/contact',
    ariaLabel:
      'Contact, this is where you can contact me about jobs or to talk accessibility.',
    component: Contact,
  },
};

const App = () => {
  const routerRoutes = React.useMemo(() => {
    const output: any = {};

    Object.keys(Routes).forEach((key) => {
      const route = Routes[key as keyof typeof Routes];
      output[route.url] = (() => route.component)();
    });

    return output;
  }, []);

  const routeResult = useRoutes(routerRoutes);

  return (
    <>
      <NavBar />
      {routeResult || <>404</>}
    </>
  );
};

export default App;

export { Routes };
