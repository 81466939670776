import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn, faGithub } from '@fortawesome/free-brands-svg-icons';

import PageTitle from '../../components/PageTitle';
import ExplainerText from '../../components/ExplainerText';
import { colors } from '../../styles';

import {
  Wrapper,
  ContentContainer,
  ExperienceContainer,
  SubTitle,
  Divider,
  Date,
  Job,
  TitleCompanyWrapper,
  JobTitle,
  Company,
  SkillsContainer,
  SkillName,
  SkillWrapper,
  SkillIconContainer,
  SkillSection,
  SocialButtons,
} from './styled-components';

import { ConnectButton } from '../Home/styled-components';

import jobs from './jobs';
import skills from './skills';

const About = () => {
  return (
    <Wrapper>
      <ContentContainer>
        <div>
          <PageTitle
            lines={['Skills &', 'Expereince']}
            decorativeText="Expereince!"
          />
          <ExplainerText>
            Hello, my name is Matt. I am a full-stack software engineer with
            over 4 years of experience developing production-level software. As
            a web/mobile developer with experience in JavaScript, React, React
            Native and Node, I’m very comfortable creating versatile
            applications with a high level of organization and a great user
            experience. I enjoy finding elegant solutions to problems and
            collaborating with a passionate team to deliver an awesome product.
            My passion for building clean and approachable technology comes from
            a deeply rooted desire to learn.
          </ExplainerText>
        </div>
        <SkillsContainer>
          <SubTitle>Skills</SubTitle>
          <SkillSection>
            {skills.map((skill) => (
              <SkillWrapper key={skill.name}>
                <SkillIconContainer>
                  {typeof skill.icon === 'string' ? (
                    skill.icon
                  ) : (
                    <FontAwesomeIcon icon={skill.icon} />
                  )}
                </SkillIconContainer>

                <SkillName>
                  {skill.name.split(' ').length > 1 ? (
                    <>
                      {skill.name.split(' ')[0]}
                      <br />
                      {skill.name.split(' ')[1]}
                    </>
                  ) : (
                    skill.name
                  )}
                </SkillName>
              </SkillWrapper>
            ))}
          </SkillSection>
        </SkillsContainer>
      </ContentContainer>
      <ExperienceContainer>
        <SubTitle>Experince</SubTitle>
        {jobs.map((job) => (
          <React.Fragment key={job.comapnyName}>
            <Divider />
            <Job>
              <Date>{job.date}</Date>
              <TitleCompanyWrapper>
                <JobTitle>{job.jobTitle}</JobTitle>
                <Company>{job.comapnyName}</Company>
              </TitleCompanyWrapper>
            </Job>
          </React.Fragment>
        ))}
        <SocialButtons>
          <ConnectButton
            brandColors={{ primary: '#2867b2', secondary: 'white' }}
            href="https://www.linkedin.com/in/mattmull/"
            title="LinkedIn"
            aria-label="Go to my linkedIn profile"
          >
            <FontAwesomeIcon icon={faLinkedinIn} />
          </ConnectButton>
          <ConnectButton
            brandColors={{ primary: '#333', secondary: '#f5f5f5' }}
            href="https://github.com/mattman240"
            title="Github"
            aria-label="Go to my github profile"
          >
            <FontAwesomeIcon icon={faGithub} />
          </ConnectButton>
          <ConnectButton
            brandColors={{ primary: colors.red }}
            href="https://go.aws/2VRSA67"
            title="Resume"
            aria-label="View my resume"
          >
            <FontAwesomeIcon icon={faFileAlt} />
          </ConnectButton>
        </SocialButtons>
      </ExperienceContainer>
    </Wrapper>
  );
};

export default About;
