import { faReact } from '@fortawesome/free-brands-svg-icons';
import {
  faMobileAlt,
  faUniversalAccess,
  faCube,
  faRobot,
} from '@fortawesome/free-solid-svg-icons';

const skills = [
  {
    name: 'React',
    icon: faReact,
  },

  {
    name: 'TypeScript',
    icon: 'TS',
  },
  {
    name: 'Accessibility',
    icon: faUniversalAccess,
  },
  {
    name: 'React Native',
    icon: faMobileAlt,
  },
  {
    name: 'Automated Testing',
    icon: faRobot,
  },

  {
    name: '3D Modeling',
    icon: faCube,
  },
];
export default skills;
