import React from 'react';

import { Link as StyledLink } from '../styled-componets';

const Link = ({
  link,
  active,
  onClick,
  className,
}: {
  link: { url: string; ariaLabel: string };
  active: string;
  onClick: (clickedItem: string) => void;
  className?: string;
}) => {
  const formatLink = (link: string) => {
    const linkText = link.split('/')[1] || 'home';
    const linkTextMultiWord = linkText.split('-');

    const capitalizeFirstLetter = (string: string) =>
      string.slice(0, 1).toUpperCase();

    const restOfWord = (string: string) => string.slice(1, linkText.length);

    return linkTextMultiWord.length > 1
      ? `${capitalizeFirstLetter(linkTextMultiWord[0])}${restOfWord(
          linkTextMultiWord[0],
        )} ${capitalizeFirstLetter(linkTextMultiWord[1])}${restOfWord(
          linkTextMultiWord[1],
        )}`
      : capitalizeFirstLetter(linkText) + restOfWord(linkText);
  };

  const formattedLinkName = formatLink(link?.url);
  console.log(link.url);
  return (
    <StyledLink
      className={className}
      isActive={
        active === '/' ? (link.url === '/' ? true : false) : active === link.url
      }
      href={link.url}
      aria-label={link.ariaLabel}
      onClick={() => onClick(link.url)}
    >
      {formattedLinkName}
    </StyledLink>
  );
};

export default Link;
