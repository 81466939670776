import React from 'react';

import { Title, TitleWrapper, DecrotaiveText } from './styled-components';

const PageTitle = ({
  lines,
  decorativeText,
}: {
  lines: string[];
  decorativeText: string;
}) => {
  return (
    <TitleWrapper>
      {lines.map((line) => (
        <Title key={line}>{line}</Title>
      ))}
      <DecrotaiveText>{decorativeText}</DecrotaiveText>
    </TitleWrapper>
  );
};

export default PageTitle;
