import React from 'react';

import {
  DashImageExplainerTextContainer,
  DashImage,
  Text,
} from './styled-components';

import dash from './dash.svg';

const ExplainerText = ({ children }: { children: string }) => {
  return (
    <DashImageExplainerTextContainer>
      <DashImage
        src={dash}
        alt="visual indentation for the paragraph after it"
      />
      <Text>{children}</Text>
    </DashImageExplainerTextContainer>
  );
};

export default ExplainerText;
