import styled from 'styled-components';

import { colors } from '../../styles';

export const Wrapper = styled.div`
  display: flex;
  padding: 40px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
  align-items: flex-start;
  flex: 1;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  margin-top: 50px;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const ExperienceContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  max-width: 100%;
  justify-content: center;
  margin: 10px;
  flex: 1;
`;

export const SubTitle = styled.h2`
  font-size: 32px;
  margin: 0;
  margin-bottom: 20px;
  color: ${colors.blue};
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 2px solid ${colors.red};
  margin: 20px 0;
`;

export const Date = styled.span`
  font-size: 15px;
  font-weight: bold;
  margin: 10px 0;
  white-space: nowrap;
  min-width: 150px;
`;

export const TitleCompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 50px;
`;

export const Job = styled.div`
  display: flex;
  width: 100%;
  color: ${colors.blue};
`;

export const JobTitle = styled.span`
  font-size: 22px;
  font-weight: bold;
`;

export const Company = styled(Date)`
  font-weight: normal;
`;

export const SkillsContainer = styled.div`
  display: flex;
  margin-top: 40px;
  color: ${colors.blue};
  flex-direction: column;
`;

export const SkillName = styled.li`
  font-size: 18px;
  margin-top: 10px;
  list-style-type: none;
`;

export const SkillWrapper = styled.div`
  flex-direction: column;
  margin-bottom: 30px;
  display: flex;
  width: 33%;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: 450px) {
    width: 50%;
  }
`;

export const SkillIconContainer = styled.div`
  background-color: ${colors.red};
  color: ${colors.white};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  > svg {
    /* overwrite fontawesome icon width */
    width: 25px;
    height: 25px;
  }
`;

export const SkillSection = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
  max-width: 450px;
`;

export const SocialButtons = styled.div`
  display: flex;
  margin-top: 40px;
`;
