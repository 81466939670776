import styled, { css } from 'styled-components';

import { colors } from '../../styles';

import { A } from 'hookrouter';

import WebLink from './Link';

export const Wrapper = styled.div`
  z-index: 500;
  height: 50px;
  background-color: ${colors.blue};
  display: flex;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  justify-content: space-between;
  font-weight: bold;
  color: ${colors.white};
  max-width: 100vw;
  position: sticky;
  top: 0px;

  @media (max-width: 700px) {
    flex-direction: row-reverse;
  }

  > .mobile {
    display: none;
    @media (max-width: 700px) {
      display: inline;
    }
  }

  > .web {
    @media (max-width: 700px) {
      display: none;
    }
  }

  > .mobileLogo {
    display: none;
    @media (max-width: 700px) {
      display: inline;
    }
  }

  > .webLogo {
    @media (max-width: 700px) {
      display: none;
    }
  }
`;

export const Link = styled(A)<{ isActive: boolean }>`
  width: fit-content;
  text-decoration: none;
  font-weight: bold;
  color: ${colors.white};
  margin-left: 20px;
  font-size: 18px;
  padding: 5px;
  :hover {
    color: ${colors.orange};
    padding-bottom: 2px;
  }
  ${({ isActive }) =>
    isActive &&
    css`
      border-bottom: 2px solid ${colors.orange};
      padding-bottom: 2px;

      @media (max-width: 700px) {
        border: none;
        color: ${colors.orange};
      }
    `}
`;

export const Logo = styled.span`
  color: ${colors.white};
  font-weight: bold;
  font-size: 25px;
  margin: 0px;
  padding: 0px;
  text-decoration: none;
`;

export const NavMenuButton = styled.button`
  background: none;
  border: none;
  padding: 0px;
  width: fit-content;
  cursor: pointer;
  display: block;
  height: fit-content;
  > svg {
    > path {
      fill: ${colors.white};
    }
  }
`;

export const MobileSlideOut = styled.div`
  top: 35px;
  z-index: 5;
  position: absolute;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 40px - 50px);
  width: calc(50vw - 40px);
  left: -20px;
  background: ${colors.blue};

  padding: 20px;
  align-items: flex-start;
`;

export const NavMenuCloseButton = styled(NavMenuButton)`
  position: absolute;
  top: -36px;
  left: 20px;
`;

export const MobileLink = styled(WebLink)`
  display: flex;
  margin: 0px;
  padding: 10px 0;
  box-sizing: border-box;
`;

export const Orange = styled.span`
  color: ${colors.orange};
`;
