import styled from 'styled-components';

import { colors } from '../../styles';

export const DashImageExplainerTextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 450px;
  margin: 20px 0;
`;

export const DashImage = styled.img`
  margin-right: 20px;
  width: 20px;
  margin-top: 8px;
`;

export const Text = styled.p`
  margin: 0px;
  font-size: 14px;
  max-width: 450px;
  color: ${colors.blue};
`;
