import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLongArrowAltUp,
  faPaperPlane,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn, faGithub } from '@fortawesome/free-brands-svg-icons';

import { Routes } from '../../App';
import { colors } from '../../styles';

import PageTitle from '../../components/PageTitle';
import ExplainerText from '../../components/ExplainerText';

import {
  MeImage,
  AccessibilityQuoteImage,
  ImageQuoteContainer,
  TopSectionContainer,
  QuickActionButton,
  ActionBar,
  ConnectWrapper,
  ConnectButton,
  ConnectButtonsWrapper,
  Wrapper,
  ContentContainer,
} from './styled-components';

import meImage from './svgs/me.svg';

import quote from './svgs/quote.svg';

const Home = () => {
  return (
    <Wrapper>
      <TopSectionContainer>
        <ContentContainer>
          <PageTitle
            lines={['Welcome to', 'Mind over Matt']}
            decorativeText="Hello!"
          />
          <ExplainerText>
            I'm a Software Engineer with experience developing for mobile and
            web with an emphasis on accessibility.
          </ExplainerText>
          <ActionBar>
            <QuickActionButton
              href={Routes.CONTACT.url}
              aria-label="Go to contact page"
            >
              Let's Chat <FontAwesomeIcon icon={faPaperPlane} />
            </QuickActionButton>
            <QuickActionButton
              href={Routes.ABOUT.url}
              color="white"
              aria-label="Go to my about page"
            >
              Learn More <FontAwesomeIcon icon={faLongArrowAltUp} />
            </QuickActionButton>
          </ActionBar>
          <ConnectWrapper>
            Check out my
            <ConnectButtonsWrapper>
              <ConnectButton
                brandColors={{ primary: '#2867b2', secondary: 'white' }}
                href="https://www.linkedin.com/in/mattmull/"
                title="LinkedIn"
                aria-label="Go to my linkedIn profile"
              >
                <FontAwesomeIcon icon={faLinkedinIn} />
              </ConnectButton>
              <ConnectButton
                brandColors={{ primary: '#333', secondary: '#f5f5f5' }}
                href="https://github.com/mattman240"
                title="Github"
                aria-label="Go to my github profile"
              >
                <FontAwesomeIcon icon={faGithub} />
              </ConnectButton>
              <ConnectButton
                brandColors={{ primary: colors.red }}
                href="https://go.aws/2VRSA67"
                title="Resume"
                aria-label="View my resume"
              >
                <FontAwesomeIcon icon={faFileAlt} />
              </ConnectButton>
            </ConnectButtonsWrapper>
          </ConnectWrapper>
        </ContentContainer>
        <ImageQuoteContainer>
          <MeImage
            src={meImage}
            alt="A picture of me wearing glasses, a hat, and a blue shirt, with an orange hexagon background, and three red decrotaive squiggle lines"
          />
          <AccessibilityQuoteImage
            alt={
              '“The power of the Web is in its universality. Access by everyone regardless of disability is an essential aspect.”'
            }
            src={quote}
          />
        </ImageQuoteContainer>
      </TopSectionContainer>
    </Wrapper>
  );
};

export default Home;
