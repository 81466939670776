const jobs = [
  {
    date: 'Feb 2019 - Present',
    jobTitle: 'Software Engineer',
    comapnyName: 'Volt Atheltics',
  },
  {
    date: 'July 2019 - Present',
    jobTitle: 'Software Engineer',
    comapnyName: 'Freelance',
  },
  {
    date: 'Jun 2018 - July 2019',
    jobTitle: 'Software Engineer',
    comapnyName: 'Trustwork',
  },
  {
    date: 'Jan 2018 - Mar 2018',
    jobTitle: 'Software Engineering Immersive Program',
    comapnyName: 'Hack Reactor',
  },
];

export default jobs;
