import React from 'react';

import { FocusScope, FocusScopeProps } from '@react-aria/focus';

interface Props extends FocusScopeProps {
  onEsc?: () => void;
}

const CustomFocusScope = ({ onEsc, children, ...rest }: Props) => {
  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onEsc?.();
    }
  };

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  });

  return <FocusScope {...rest}>{children}</FocusScope>;
};

export default CustomFocusScope;
