import React from 'react';

import PageTitle from '../../components/PageTitle';
import { colors } from '../../styles';

import {
  Wrapper,
  ContentContainer,
  Divider,
  Label,
  Details,
  EmailWrapper,
  SvgButtonContainer,
  EmailMeButton,
  LetsChat,
} from './styled-components';

const Contact = () => {
  const email = 'mattmull98@gmail.com';
  const emailTemplate = `mailto:${email}?subject=M/M%20Connection%20Request`;

  return (
    <Wrapper>
      <ContentContainer>
        <PageTitle
          lines={["Let's Create", 'Together']}
          decorativeText="Contact!"
        />
        <Divider />
        <EmailWrapper href={emailTemplate}>
          <Label>Email:</Label> <Details>{email}</Details>
        </EmailWrapper>
        <Divider />
        <Label>Location:</Label> <Details>Remote, USA</Details>
      </ContentContainer>
      <SvgButtonContainer>
        <svg
          width="327"
          height="366"
          viewBox="0 0 327 366"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M161.088 1.26059L320.39 93.2442L326.075 264.827L165.502 365.333L9.00862 288.144L0.537345 101.753L161.088 1.26059Z"
            stroke={colors.red}
            strokeWidth="2"
          />
          <path
            d="M30 191L279.5 271"
            stroke={colors.red}
            strokeLinecap="square"
            strokeWidth="2"
          />
        </svg>
        <EmailMeButton href={emailTemplate}>
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.5167 1.48331C20.5167 0.93102 20.069 0.483305 19.5167 0.483305H10.5167C9.96441 0.483305 9.5167 0.93102 9.5167 1.48331C9.5167 2.03559 9.96441 2.48331 10.5167 2.48331H18.5167V10.4833C18.5167 11.0356 18.9644 11.4833 19.5167 11.4833C20.069 11.4833 20.5167 11.0356 20.5167 10.4833V1.48331ZM1.70711 20.7071L20.2238 2.19041L18.8096 0.776198L0.292893 19.2929L1.70711 20.7071Z"
              fill={colors.white}
            />
          </svg>
        </EmailMeButton>
        <LetsChat>Let's Chat</LetsChat>
      </SvgButtonContainer>
    </Wrapper>
  );
};

export default Contact;
