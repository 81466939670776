import styled from 'styled-components';

import { colors } from '../../styles';

export const Title = styled.h1`
  margin: 0px;
  font-size: 64px;
  position: relative;
  color: ${colors.blue};

  @media (max-width: 700px) {
    font-size: 45px;
  }
  @media (max-width: 300px) {
    font-size: 30px;
  }
`;

export const DecrotaiveText = styled.span`
  color: ${colors.red};
  position: absolute;
  left: -30px;
  top: -8px;
  font-size: 15px;
  font-weight: bold;
  transform: rotate(-35deg);

  @media (max-width: 700px) {
    display: none;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 700px) {
    justify-content: center;
    text-align: center;
  }
`;
